import Vue from "vue"
import VueI18n from "vue-i18n"
import axios from "axios"
import { localMonitor } from "@shared/helpers/env-helpers.js"

const countries = require("i18n-iso-countries");

import messages_en from "./locales/en.yml"

Vue.use(VueI18n)

const SUPPORTED_LANGUAGES = (
  localMonitor ?
  [
    "en",
    "fr"
  ] :
  [
    "ar",
    "de",
    "da",
    "el",
    "en",
    "es",
    "fr",
    "it",
    "nl",
    "pl",
    "pt",
    "ro",
    "sk",
    "no",
    "sv",
    "tr",
    'cs'
  ]
)

SUPPORTED_LANGUAGES.forEach((language) => {
  countries.registerLocale(require(`i18n-iso-countries/langs/${language}.json`));
});

Vue.prototype.$translateCountryCode = function (countryCode) {
  return countries.getName(countryCode, i18n.locale, { select: "official" });
};

export const LANGUAGES_NAME = {
  de: "de",
  en: "gb",
  ar: "ar",
  ca: "ca",
  da: "dk",
  es: "es",
  fr: "fr",
  el: "gr",
  it: "it",
  nl: "nl",
  pl: "pl",
  pt: "pt",
  sk: "sk",
  cs: "cz",
  sv: "sv",
  no: "no",
  tr: "tr"
}

const DEFAULT_LANGUAGE = "en"

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  silentTranslationWarn: true,
  messages: {
    en: messages_en
  }
})

const loadedLanguages = [DEFAULT_LANGUAGE]
export let rootPath = null

export function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common["Accept-Language"] = lang
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  document.querySelector("html").setAttribute("lang", lang)
  localStorage.locale = lang
  localStorage.locale_name = LANGUAGES_NAME[lang]

  return lang
}

function getDefaultLanguage() {
  let lang = null

  if (SUPPORTED_LANGUAGES.includes(localStorage.locale)) {
    lang = localStorage.locale
  } else if (navigator.languages) {
    lang = navigator.languages.map(locale => locale.split("-")[0]).find(locale => locale === "fr") ? "fr" : DEFAULT_LANGUAGE
  } else {
    lang = (navigator.language || navigator.userLanguage || DEFAULT_LANGUAGE).split("-")[0]
  }

  return lang || DEFAULT_LANGUAGE
}

export function setLanguageAsync(lang = "default", options = { rootPath: null }) {
  lang = lang === "default" ? getDefaultLanguage() : lang
  lang = SUPPORTED_LANGUAGES.includes(lang) ? lang : DEFAULT_LANGUAGE

  if (options.rootPath) {
    rootPath = options.rootPath
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet

  return import(
    /* webpackChunkName: "lang-[request]" */ `./locales/${lang}.yml`
  ).then(messages => {
    i18n.setLocaleMessage(lang, messages)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

// Override translation functions to better handle fallback and track empty translation
// keys.

window.emptyTranslationKeys = []

const $tInitialFunc = Vue.prototype.$t
Vue.prototype.$t = function (key, ...args) {
  const translation = $tInitialFunc.apply(window.app, [key, ...args])
  if (translation === '') {
    window.emptyTranslationKeys.push(key)
    return $tInitialFunc.apply(window.app, [key, DEFAULT_LANGUAGE, ...args])
  }
  return translation
}

const $tcInitialFunc = Vue.prototype.$tc
Vue.prototype.$tc = function (key, number = 1, ...args) {
  const translation = $tcInitialFunc.apply(window.app, [key, number, ...args])
  if (translation === '') {
    window.emptyTranslationKeys.push(key)
    return $tcInitialFunc.apply(window.app, [key, number, DEFAULT_LANGUAGE, ...args])
  }
  return translation
}
