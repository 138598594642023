// Icons & Badges
import WSourceIcon from './WSourceIcon'
import WIcon from './WIcon'
import WIconsBase from './WIconsBase'
import WBadge from './WBadge'
import WFlagIcon from './WFlagIcon'
import WTrustvilleBadge from './WTrustvilleBadge'
import WStarsAndReviews from './WStarsAndReviews'

// Charts
import WLineChart from './WLineChart'
import WTimelineChart from './WTimelineChart'
import WBasedChart from './WBasedChart'
import WStackedBarChart from './WStackedBarChart'

// Selects & Pickers
import WDatePicker from './WDatePicker'
import WLocaleSelect from './WLocaleSelect'
import WMenuSelect from './WMenuSelect'
import WDropDownMenu from './WDropDownMenu'
import WDateTimePickerField from './WDateTimePickerField'
import WEmojiPicker from './WEmojiPicker'
import WAutocomplete from './WAutocomplete'
import WSearchInput from './WSearchInput'
import WBubble from './WBubble'
import WFeelingIcon from './WFeelingIcon'
import WFileReader from './WFileReader'
import WTreeView from './WTreeView'
import WTreeSelect from './WTreeSelect'

//Buttons
import WZoomButton from './WZoomButton'
import WCopyButton from './WCopyButton'

// Formatting
import WQuote from './WQuote'
import WScore from './WScore'
import WStackedBar from './WStackedBar'
import WDate from './WDate'
import WStatsScore from './WStatsScore'
import WTooltip from './WTooltip'
import WTrunc from './WTrunc'

// Layout
import WFooter from './layout/WFooter'
import WFormLayout from './WFormLayout'
import WFormInput from './WFormInput'

// Template
import WLateralSectionLayout from "./WLateralSectionLayout"
import WWidget from "./WWidget"
import WStatsNpsWidget from "./WStatsNpsWidget"
import WLocalCompetitorsWidget from "./WLocalCompetitorsWidget"
import WStatsWidget from "./WStatsWidget"
import WStatsSection from "./WStatsSection"

// Widgets
import WPositionWidget from "./WPositionWidget"
import WRank from "./WRank"
import WWeightRepartition from "./WWeightRepartition"
import WStatsNumber from "./WStatsNumber"

// Others
import WTable from './WTable'
import WRepartitionHorizontalFrieze from './WRepartitionHorizontalFrieze'
import WRepartitionVerticalFrieze from './WRepartitionVerticalFrieze'
import WNpsRepartition from './WNpsRepartition'
import WSnackBar from './WSnackBar'
import WCode from './WCode'
import WNavTabs from './WNavTabs'
import WSandbox from './WSandbox'
import WPdfSandbox from './WPdfSandbox'
import WColorSign from './WColorSign'
import WRatingCircular from './WRatingCircular'
import WRatingCircularWithEvol from './WRatingCircularWithEvol'
import WRatingWithStars from './WRatingWithStars'
import WRatingText from './WRatingText'
import WSideBarListItem from './WSideBarListItem'
import WPasswordSetupDialog from './WPasswordSetupDialog'
import WInfiniteScroll from './WInfiniteScroll'
import WRateBar from './WRateBar'
import WProfiler from './WProfiler'
import WScoreRange from './WScoreRange'
import WTopBadge from './WTopBadge'
import WDataTable from './WDataTable'
import WScheduledActionTraceTable from './WScheduledActionTraceTable'
import WHr from './WHr'
import WTimelineElement from './WTimelineElement'

export default {
  WScore,
  WBadge,
  WDate,
  WDatePicker,
  WDateTimePickerField,
  WEmojiPicker,
  WAutocomplete,
  WIcon,
  WFlagIcon,
  WIconsBase,
  WTrustvilleBadge,
  WLineChart,
  WTimelineChart,
  WBasedChart,
  WLocaleSelect,
  WMenuSelect,
  WQuote,
  WSnackBar,
  WSourceIcon,
  WStackedBar,
  WStackedBarChart,
  WTable,
  WRepartitionHorizontalFrieze,
  WRepartitionVerticalFrieze,
  WNpsRepartition,
  WCode,
  WNavTabs,
  WSandbox,
  WLateralSectionLayout,
  WColorSign,
  WWidget,
  WStatsWidget,
  WStatsNpsWidget,
  WLocalCompetitorsWidget,
  WStatsSection,
  WPositionWidget,
  WStatsScore,
  WFooter,
  WRank,
  WStatsNumber,
  WDropDownMenu,
  WPdfSandbox,
  WSideBarListItem,
  WWeightRepartition,
  WPasswordSetupDialog,
  WInfiniteScroll,
  WRateBar,
  WStarsAndReviews,
  WSearchInput,
  WProfiler,
  WTooltip,
  WRatingCircular,
  WRatingCircularWithEvol,
  WRatingWithStars,
  WRatingText,
  WScoreRange,
  WTopBadge,
  WTrunc,
  WBubble,
  WFeelingIcon,
  WDataTable,
  WZoomButton,
  WCopyButton,
  WFileReader,
  WTreeView,
  WTreeSelect,
  WScheduledActionTraceTable,
  WFormLayout,
  WFormInput,
  WHr,
  WTimelineElement
}
