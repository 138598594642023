<template>
  <div class="autologin-advanced-search f-14">
    <div class="autologin-advanced-search-title d-flex">
      <div class="align-self-center">{{ this.$i18n.t('components.autologin.advanced_search.advanced_search') }}</div>
      <div class="align-self-center">
        <v-icon v-on:click="toggleAdvancedSearch">
          {{ advancedSearchOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </div>
    </div>

    <div v-if="advancedSearchOpen">
      <div>
        <v-autocomplete
          v-model="selectedFeatures"
          :items="features"
          :label="this.$i18n.t('components.autologin.advanced_search.features')"
          multiple
          clearable
          chips
          small-chips
          deletable-chips
        >
          <template v-slot:item="{ item }">
            <v-list-item-action>
              <v-checkbox
                :input-value="item.selected"
                class="pr-2"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="item.enabled"
                :disabled="!item.selected"
                :label="switchLabel(item)"
                :color="switchColor(item)"
                @click.stop="onClickFeatureState(item)"
                class="pr-2"
              />
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <v-autocomplete
          :items="brands"
          :label="brandsInputTitle"
          v-model="selectedBrands"
          multiple
          clearable
          chips
          small-chips
          deletable-chips
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ADVANCED_SEARCH_FEATURES } from "./features"

  export default {
    name: 'AutologinAdvancedSearch',
    data: () => ({
      advancedSearchOpen: false,
      features: ADVANCED_SEARCH_FEATURES,
      selectedFeatures: [],
      brands: [],
      allBrands: [],
      selectedBrands: []
    }),
    computed: {
      brandsInputTitle() {
        let translation_key = this.brands.length <= 1 ? 'n_brand_account' : 'n_brands_accounts'
        return this.$i18n.t('components.autologin.advanced_search.' + translation_key, { count: this.brands.length })
      }
    },
    methods: {
      toggleAdvancedSearch() {
        this.advancedSearchOpen = !this.advancedSearchOpen
        if (this.allBrands.length == 0) this.fetchBrands()
      },
      toggleLoading(value) {
        this.$emit("toggleLoading", value)
      },
      onClickFeatureState(feature){
        this.filterBrands(feature)
      },
      resetStateForUnselectedFeatures(){
        this.features.forEach(async feature => {
          if(feature.selected == false) feature.enabled = feature.defaultEnabled
        })
      },
      switchLabel(feature){
        let translation_key = feature.enabled ? 'yes' : 'no'
        return this.$i18n.t('components.autologin.advanced_search.' + translation_key)
      },
      switchColor(feature){
        if(feature.selected) return feature.enabled ? 'green' : 'red'
        return 'grey'
      },
      updateFeaturesSelection(){
        this.features.forEach(feature => feature.selected = this.selectedFeatures.includes(feature.value))
      },
      async fetchBrands() {
        this.toggleLoading(true)
        const brandsRequest = this.$adminBasedRequest()
                                  .select({ brands: ["id", "name"]})
                                  .where({ monitored: true })
                                  .order(['name', 'asc'])
        const brandsResponse = await this.$resolve(brandsRequest)
        this.brands = this.allBrands = brandsResponse.data?.brands?.map(
          (brand) => ({ value: brand.id, text: brand.name })
        )
        this.toggleLoading(false)
      },
      async filterBrands() {
        this.brands = this.allBrands

        let withFeatures = {}
        this.features.filter(feature => feature.selected).forEach(async feature => {
          withFeatures[feature.value] = feature.enabled
        })

        let request = this.$adminBasedRequest().select({ brands: ['id']})
                                               .where({ monitored: true, with_features: { enabled: withFeatures } })
                                               .group('id')

        let response = await this.$resolve(request)
        let filteredBrandsIds = Object.values(response.data).map( (brand) => brand.id )

        this.brands = this.brands.filter(brand => filteredBrandsIds.includes(brand.value))
        this.selectedBrands = this.selectedBrands.filter(brand => filteredBrandsIds.includes(brand.value))
      }
    },
    watch: {
      selectedFeatures() {
        this.updateFeaturesSelection()
        this.resetStateForUnselectedFeatures()
        this.filterBrands()
      },
      selectedBrands() {
        this.$emit("advancedSearchBrandsChange", this.selectedBrands)
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .autologin-advanced-search
    width: 100%

    .v-input >>> .v-input__control .v-input__icon
      cursor: pointer

    .v-input >>> .v-input__control .v-select__slot
      padding-top: 2px

      .v-select__selections
        padding-top: 5px
        padding-bottom: 5px

      .v-chip--select
        margin: 1px 3px 3px 0

    .v-input.v-select.v-text-field:not(.v-text-field--single-line) >>> input
      margin-top: -1px
      padding-top: 0

  .autologin-advanced-search-title
    min-height: 34px

  .v-autocomplete__content
    .v-list >>> .v-list-item .v-list-item__action:first-child
      margin-right: 5px

    .v-input--switch >>> .v-label
      margin-left: 10px

    .v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset) >>> .v-input--switch__thumb
      color: #F44336

    .v-input--switch >>> .v-input--switch__track
      color: #F44336
</style>
