 <template>
  <div
    class="nav-tabs fs-13"
    :class="{ 'pl-0': scrollable, 'mobile': switchToMobile }"
  >
    <div class="nav-tabs-col align-center">

      <v-slide-group
        v-if="scrollable"
        show-arrows
        center-active
        v-model="model"
      >
        <v-slide-item
          v-for="(routerLinkProperty, index) in routerLinksProperties"
          :key="index"
          class="mx-3"
          v-slot="{ active, toggle }"
          ref="navs"
        >
          <Nav
            :routerLinkProperty="routerLinkProperty"
            :replaceTitle="replaceTitle"
          />
        </v-slide-item>
      </v-slide-group>

      <Nav
        v-else
        v-for="routerLinkProperty in routerLinksProperties"
        :key="routerLinkProperty.title"
        :routerLinkProperty="routerLinkProperty"
      />
    </div>
  </div>
</template>

<script>
  import Nav from './Nav'

  export default {
    name: "WNavTabs",
    components: {
      Nav,
    },
    data() {
      return {
        model: null
      }
    },
    props: {
      routerLinksProperties: {
        type: Array,
        required: true
      },
      scrollable: {
        type: Boolean,
        required: false,
        default: false
      },
      replaceTitle: {
        type: false,
        required: false,
        default: true
      },
      switchToMobile: {
        required: false,
        default: true
      }
    },
    methods: {
      updateActive() {
        if (!this.scrollable) {
          return
        }

        const index = this.$refs.navs.findIndex((nav) => nav.$el.classList.contains('link-active'))

        this.model = index
      }
    },
    mounted() {
      this.$nextTick(function () {
        this.updateActive()
      })
    },
    watch:{
      $route (to, from){
        this.updateActive()
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'
  @import '~@theme/medias.styl'
  @import '~@theme/constants.styl'

  .nav-tabs
    height: $secondary-menu-height
    padding-left: 15px
    box-sizing: border-box

    .nav-tabs-col
      display: flex
      height: $secondary-menu-height
      justify-content: flex-start
      .nav-tabs-link
        margin-right: 20px
        a
          display: inline-block
          width: 100%
          cursor: pointer
      .v-slide-group__prev, .v-slide-group__next
        min-width: 30px
        max-width: 30px

  +forBreakpoints(mobile)
    .nav-tabs.mobile
      text-transform uppercase
      padding: 0
      background-color: $palegrey
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .2) !important
      .active
        border: none
      .nav-tabs-col
        height: $secondary-menu-height
        padding: 0
        justify-content: space-around
        .nav-tabs-link
          text-align: center
          height: $secondary-menu-height
          margin-right: 0px

</style>
