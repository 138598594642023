<template>
  <flag :iso="flagLocale" class="rounded-flag ml-2 mr-2" />
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagLocale() {
      return (
        {
          en: 'gb',
          el: 'gr',
          cs: 'cz',
          da: 'dk'
        }[this.locale] || this.locale
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.rounded-flag
  border-radius 1em
</style>
