<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        <h3 class="mb-3">{{ $t('create_password') }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit="createPassword">
          <v-text-field
            class="password"
            v-model="password"
            :error-count="5"
            :rules="passwordRules"
            :label="$t('new_password')"
            name="password"
            prepend-icon="mdi-lock"
            type="password"
            dense
            outlined
          />

          <v-text-field
            class="password-confirmation"
            v-model="confirmation"
            :rules="[$required, confirmMatch]"
            :label="$t('password_confirmation')"
            name="confirmation"
            prepend-icon="mdi-lock"
            type="password"
            dense
            outlined
          />

          <div class="text-center">
            <v-btn
              class="save"
              :disabled="!isValid || loading"
              :ripple="false"
              :loading="loading"
              @click.prevent="createPassword"
              color="secondary"
              depressed
            >
              {{ $t('validate') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: "WPasswordSetupDialog",
  props: {},
  data() {
    return {
      dialog: true,
      isValid: false,
      loading: false,
      password: '',
      confirmation: ''
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserId",
      "currentUserUseStrongPassword"
    ]),
    confirmMatch() {
      return (
        this.password === this.confirmation ||
        this.$t('password_confirmation_mismatch')
      )
    },
    passwordRules() {
      return this.currentUserUseStrongPassword ? [
        v => !!v,
        v => (v === "" || v.length >= 16) || this.$t('password_too_short'),
        v => /[a-zA-Z]+/.test(v) || this.$t('password_no_letter'),
        v => /[0-9]+/.test(v) || this.$t('password_no_number'),
        v => (v !== "" && !/^[a-zA-Z0-9]+$/.test(v)) || this.$t('password_no_special_character'),
      ] : [
        v => !!v,
        v => (v === "" || v.length >= 16) || this.$t('password_too_short'),
      ]
    }
  },
  methods: {
    async createPassword() {
      if (this.isValid) {
        this.loading = true

        const response = await this.$api.wizville.users.update(
          this.currentUserId, { password: this.password }
        )

        if (response.status === 'ok' ) {
          this.dialog = false
          this.$store.dispatch('notifySuccess', {
            message: this.$t('flash_message_successfully_created_password')
          })
        }
        this.loading = false;
      }
    }
  }
}
</script>

<style lang='stylus'>

</style>
