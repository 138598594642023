<template>
  <div class="w-timeline-chart" :style="chartStyle">
    <h2
      v-if="!!title"
      class="text-f10"
    >
      {{ title }}
    </h2>

    <v-skeleton-loader v-if="isFirstLoading" type="image,  table-row"/>
    <div v-else>
      <div class="chart-timeline-loading" v-if="isLoading">
        <v-progress-linear indeterminate color="#144C6E"></v-progress-linear>
      </div>
      <div class="chart-container" :style="{ height: this.height }">
        <div class="chart">
          <Chart :options="chartOptions" ref="chart" :width="100" :height="100" />
        </div>
      </div>
      <div class="chart-timeline-navigation" :style="navStyle">
        <button
          data-html2canvas-ignore
          v-if="options.from > options.minDate"
          class="previous"
          v-on:click="goPrevious"
        >
          <v-icon left>mdi-arrow-left</v-icon>
        </button>
        <button
          data-html2canvas-ignore
          v-if="options.to < options.maxDate"
          class="next"
          v-on:click="goNext"
        >
          <v-icon right>mdi-arrow-right</v-icon>
        </button>
      </div>

    </div>

  </div>
</template>

<style lang="stylus">
  .chart-container
    position: relative
    width: 100%

    .chart
      position: absolute
      width: 100%

  .chart-timeline-navigation
    margin-left: 18px
    position: relative
    padding: 8px

  .chart-timeline-navigation button.previous
    float: left

  .chart-timeline-navigation button.next
    float: right

  .chart-timeline-loading
    margin-right: 1px

</style>

<script>
import { mapGetters } from 'vuex'
import { Chart } from "highcharts-vue"
import ExportingHighcharts from "highcharts/modules/exporting"
import Highcharts from "highcharts";
import dayjs from "dayjs"
import { i18n } from '@i18n/setup'
import _debounce from "lodash/debounce"
import _values from "lodash/values"
import _keys from "lodash/keys"
import _filter from "lodash/filter"
import _cloneDeep from "lodash/cloneDeep"
import { numberWithSeparator } from "@shared/helpers/formatters.js"
import chartToPng from "@shared/helpers/chart-to-png"
import Pdf from '@shared/helpers/exportToPdf/pdf'

ExportingHighcharts(Highcharts);

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const DEFAULT_COLORS = ["#FFB800", "#9FBAB8", "#B27B59", "#DB3572", "#88C788", "#BA0022", "#2E1C4D", "#C48754", "#4EBDB7", "#4F64B6", "#C1CC75", "#DBA951", "#B96785", "#874B8B", "#4BAEE4", "#6F63BA", "#2699A6", "#9D8B52", "#7DBD96", "#278DDA", "#B473BB", "#98BBB8", "#98A255", "#E8DF81", "#BD7953", "#E2BCA4"]

export default {
  name: "WTimelineChart",
  data() {
    return {
      isLoading: false,
      isFirstLoading: true,
      series: [],
      categories: [],
      currentTime: Date.now(),
      history: {},
      navStyle: "margin-top: -46px",
      chartStyle: "padding-bottom: 60px !important",
      periodSelected: [],
      options: {}
    }
  },

  components: { Chart },

  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    defaultOptions: {
      type: Object,
      required: true
    },
    colorLines: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    },
    height: {
      type: String,
      required: false,
      default: "400px"
    },
    colorBackground: {
      type: String,
      required: false,
      default: "#fff"
    },
    colorBorders: {
      type: String,
      required: false,
      default: "#deefee"
    },
    colorChartBorders: {
      type: String,
      required: false,
      default: "transparent"
    },
    colorPeriod: {
      type: String,
      required: false,
      default: "#E5EAF2"
    },
    colorMarkerMainLine: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    },
    connectNulls: {
      type: Boolean,
      required: false,
      default: true
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'YYYY-MM-DD'
    },
    type: {
      type: String,
      required: false,
      default: 'line'
    },
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    decimals: {
      type: Number,
      required: false,
      default: 1
    },
    yAxisLabelsFormatter: {
      type: Function,
      required: false,
      default() {
        return null
      }
    }
  },

  mounted: function() {
    this.refreshOptions()
    this.refreshData()
  },

  computed: {
    ...mapGetters(['currentUser']),
    isDataPresent() {
      return (this.data && !!Object.keys(this.data || {}).length)
    },
    chartOptions () {
      var self = this

      let options = {
        chart: {
          events: {
            render: function() {
              setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 100)
            }
          },
          type: "spline",
          backgroundColor: this.colorBackground,
          borderColor: this.colorChartBorders,
          borderWidth: 1,
          height: this.height,
          style: {
            fontFamily: 'SourceSansPro'
          },
        },
        legend: {
          enabled: (this.options.legendEnabled === true ? true : false)
        },
        credits: { enabled: false },
        title: { text: "" },
        tooltip: {
          shared: false,
          useHTML: true,
          shadow: false,
          borderRadius: 8,
          borderWidth: 0,
          padding: 0,
          formatter: this.options.tooltip
        },
        xAxis: {
          fill: this.colorBorders,
          categories: this.categories,
          gridLineColor: this.colorBorders,
          gridLineWidth: 1,
          min: 1,
          max: this.options.periodNumber
        },
        plotOptions: {
          series: {
            lineWidth: 3,
            connectNulls: this.connectNulls,
            marker: {
              symbol: "circle",
              fillColor: "white",
              lineWidth: 2,
              lineColor: null
            },
            negativeColor: this.$colors.error
          },
          spline: { dataLabels: { enabled: false } }
        },
        yAxis: {
          gridLineColor: this.colorBorders,
          gridLineWidth: 1,
          title: { text: "", step: 2 },
          tickInterval: this.options.tickInterval,
          min: this.min,
          max: this.max,
          labels: {
            formatter: function() {
              const yLabel = self.yAxisLabelsFormatter(this.value)
              if (yLabel === null) {
                return this.value
              } else {
                return self.yAxisLabelsFormatter(this.value)
              }
            }
          }
        },
        exporting: {
          enabled: false
        }
      }

      let series = this.series.map ( (s, index) => {
        return {
          ...s,
          color: this.colorLines[index],
          dashStyle: "Solid",
          type: this.type,
          dataLabels : {
            enabled : !this.options.markerEnabled,
            color: this.options.markerColor || this.colorMarkerMainLine[index],
            formatter : function() {
              return this.y?.toFixed(self.decimals)
            }
          },
          marker: {
            fillColor: this.options.markerColor || this.colorMarkerMainLine[index],
            enabled: this.options.markerEnabled,
            radius: 3
          }
        }
      })

      const chartOptions = { ...options, ...this.options.chartOptions }

      return { ...chartOptions, series }
    },
    min() {
      let min = this.options.min

      if (this.options.chartZoom) {
        for (const serie of this.series) {
          for (const dataPoint of serie.data) {
            if (dataPoint) {
              const zoomedDataPoint = (dataPoint - this.options.chartZoom)

              if (this.options.min && zoomedDataPoint < this.options.min) {
                return this.options.min
              } else if (min && zoomedDataPoint < min) {
                min =  Math.floor(zoomedDataPoint)
              } else if (!min) {
                min =  Math.floor(zoomedDataPoint)
              }
            }
          }
        }
      }

      return (min ? min : undefined)
    },
    max() {
      let max = this.options.max

      if (this.options.chartZoom) {
        for (const serie of this.series) {
          for (const dataPoint of serie.data) {
            if (dataPoint) {
              const zoomedDataPoint = dataPoint + this.options.chartZoom

              if (this.options.max && zoomedDataPoint > this.options.max) {
                return this.options.max
              } else if (max && zoomedDataPoint > max) {
                 max = Math.ceil(zoomedDataPoint)
              } else if (!max) {
                max = Math.ceil(zoomedDataPoint)
              }
            }
          }
        }
      }

      return (max ? max : undefined)
    }
  },

  methods: {
    refreshOptions() {
      const self = this
      this.options = this.defaultOptions;
      this.options.legendEnabled = !(this.options.legendEnabled === false)
      this.options.markerEnabled = !(this.options.markerEnabled === false)
      this.options.dataPointColor = this.options.dataPointColor || null
      this.options.maxDate = this.options.maxDate || dayjs()
      this.options.minDate = this.options.minDate || dayjs().subtract(100, 'years')
      if(typeof(this.options.maxDate) === "string") this.options.maxDate = dayjs(this.options.maxDate, this.dateFormat)
      if(typeof(this.options.minDate) === "string") this.options.minDate = dayjs(this.options.minDate, this.dateFormat)

      this.options.tickUnit = this.options.tickUnit || "day"
      this.options.unit = this.options.unit || this.options.tickUnit + "s"
      this.options.tickNumber = this.options.tickNumber || 1

      this.options.tooltipFields = this.options.tooltipFields || []
      this.options.valueKey = this.options.valueKey || "avgScore"

      this.options.chartOptions = this.options.chartOptions || {}

      this.options.tooltip = this.options.tooltip || function() {
        let _this = this
        let userOptions = this.series.userOptions
        let rawData = userOptions.rawData
        let tooltipFields = userOptions.tooltipFields
        let score = Number(rawData[this.point.index][userOptions.valueKey].toFixed(self.decimals))
        let html = '<div>'
        html = '<h1 style="background: #454F55; padding: 10px; color: #fff; font-size: 14px; text-align: center;">' + this.key + '</h1>'
        html += '<div style="background: #454F55; padding: 10px; color: #ACB6C1;text-align:center;">'
        html += '<div>' + this.series.name + "</div><span style='font-weight: 700;color: white; font-size: 24px;'>" + score + "</span>"
        tooltipFields.forEach(function(field) {
          html += '<div>' + numberWithSeparator(rawData[_this.point.index][_keys(field)[0]]) + " " + _values(field)[0] +"</div>"
        })
        html += "</div>"
        html += "</div>"

        return html
      }

      var period_number = 8

      switch (this.options.unit) {
        case 'days':
          period_number = 7;
          break;
        case 'weeks':
          period_number = 4;
          break;
        case 'months':
          period_number = 6;
          break;
        case 'years':
          period_number = 4;
          break;
      }

      this.options.periodNumber = this.options.periodNumber || period_number
      this.options.toTimeSeriesOptions = this.options.toTimeSeriesOptions || {}
      this.options.toTimeSeriesOptions.limit = this.options.toTimeSeriesOptions.limit || this.options.periodNumber
      this.options.to = this.options.to || dayjs()

      if(typeof(this.options.to) === "string") this.options.to = dayjs(this.options.to, this.dateFormat)

      this.options.from = this.options.from || this.options.to.subtract(this.options.periodNumber, this.options.unit)
      if(typeof(this.options.from) === "string") this.options.from = dayjs(this.options.to, this.dateFormat)


      if(this.options.unit == "weeks") {
        this.options.from = this.options.from.endOf('week').add(1, 'day').startOf('day')
        this.options.to = this.options.to.endOf('week').endOf('day')
      }

      if(this.options.unit == "quarters") {
        this.options.from = this.options.from.endOf('quarter').add(1, 'day').startOf('quarter').add(this.currentUser.quarterOffset, "month")
        this.options.to = this.options.to.endOf('quarter').add(this.currentUser.quarterOffset, "month")
      }

      if(this.options.unit == "months") {
        this.options.from = this.options.from.endOf('month').add(1, 'day').startOf('month')
        this.options.to = this.options.to.endOf('month')
      }

      if(this.options.unit == "years") {
        this.options.from = this.options.from.endOf('year').add(1, 'day').startOf('year')
        this.options.to = this.options.to.endOf('year')
      }

      if(this.options.toTimeSeriesOptions) {
        this.options.toTimeSeriesOptions.limit = this.options.toTimeSeriesOptions.limit || this.options.periodNumber
      }
    },
    dataChanged(){
      this.refreshOptions()
      // this.refreshData()
    },
    displayExtra(self, graph) {
      // Adjust navigation arrows position
      self.navStyle = "margin-top: -" + (graph.containerHeight - graph.plotHeight - 5) + "px"
      self.chartStyle = "padding-bottom: " + (graph.containerHeight - graph.plotHeight - 30) + "px !important"

      // Display period
      self.periodSelected.forEach((sel) => sel.destroy())
      self.periodSelected = []
      let columnWidth = graph.plotBox.width / self.options.periodNumber
      let multiplicator = self.period_to_selector - self.period_from_selector + 1
      // self.periodSelected.push(graph.renderer.rect(graph.plotBox.x + columnWidth * (self.period_from_selector - 1), graph.plotBox.y, columnWidth * multiplicator, graph.plotBox.height, 0)
      //   .attr({
      //     'stroke-width': 0,
      //     fill: '#555',
      //     zIndex: -1,
      //     opacity: 0.05
      //   })
      //   .add()
      // )
    },
    exportToPng(fileName, title, subtitle) {
      this.$store.dispatch("notifyInfo");
      const chart = this.$refs.chart.chart;

      chartToPng(chart, { width: 700, fileName, title, subtitle });
    },
    async exportToPdf(fileName, title, subTitle) {
      this.$store.dispatch("notifyInfo");

      const chart = this.$refs.chart.chart;
      const pdf = new Pdf({
        defaultBodyMargin: { left: 40, top: 40 }
      });

      await pdf.addPage()

      const element = document.getElementById(this.exportElementId);

      if (title) {
        await pdf.addRow({}, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addText(title, { color: "#212121", font: { style: 'semi-bold' } });
          });
        });
      }
      if (subTitle) {
        await pdf.addRow({}, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addText(subTitle, { fontSize: 6, color: "#666", font: { style: 'semi-bold' } });
          });
        });
      }
      await pdf.addRow({ marginTop: 20 }, async (row) => {
        await row.addCol({ width: '12' }, async (col) => {
          await col.addChart(chart, { width: 700 });
        });
      });

      pdf.save(fileName);
    },
    goNext() {
      if(this.options.to < this.options.maxDate) {
        this.options.from = this.options.from.add(
          this.options.periodNumber,
          this.options.unit
        )
        this.options.to = this.options.to.add(
          this.options.periodNumber,
          this.options.unit
        ).endOf(this.options.unit)
        this.refreshData()
      }
    },

    goPrevious() {
      if(this.options.from > this.options.minDate) {
        this.options.from = this.options.from.subtract(
          this.options.periodNumber,
          this.options.unit
        )
        this.options.to = this.options.to.subtract(
          this.options.periodNumber,
          this.options.unit
        ).endOf(this.options.unit)
        this.refreshData()
      }
    },

    async getData() {
      var self = this
      var opts = this.options
      var key = opts.from.format("YYYY-MM-DD") + "-" + opts.to.format("YYYY-MM-DD")

      if(opts && opts.toTimeSeriesOptions) {
        opts.toTimeSeriesOptions.date_begin = opts.from.subtract(1, self.options.tickUnit).format("YYYY-MM-DD")
        opts.toTimeSeriesOptions.date_end = opts.to.add(1, self.options.tickUnit).format("YYYY-MM-DD")
        opts.toTimeSeriesOptions.limit += 2
        if(!opts.toTimeSeriesOptions.cumulative && !this.isDataPresent) {
          opts.request = opts.request.dateBetween(opts.toTimeSeriesOptions.date_begin, opts.toTimeSeriesOptions.date_end)
        }
      }
        const response = await opts.request.resolve(
          "WTimelineChart",
          {
            tickNumber: opts.tickNumber,
            tickUnit: opts.tickUnit,
            timeSerieParams: opts.toTimeSeriesOptions
          },
          "time_series"
        )
        this.history[key] = response.data

      return _cloneDeep(this.history[key])
    },

    refreshData: _debounce(function() {
      var self = this
      self.period_from_selector = 1000
      self.period_to_selector = 1000
      if(!this.isFirstLoading) this.isLoading = true

      self.$emit('loadingStatusChanged', true);

      this.getData().then(function(data) {
        if((data?.series?.length || 0) == 0) {
          data.series = [
            {
              name: i18n.t("w_timeline_chart_no_data"),
              data: [...Array(data?.labels?.length || 0).keys()].map(i => 0)
            }
          ]
        }
        // Prepare the series and store some useful informations inside it (eg. valueKey, rawData, tooltipFields)
        let series = data.series

        series.forEach(function(serie) {
          serie.rawData = serie.data

          serie.data = serie.data.map((dataPoint) => {
            return dataPoint && dataPoint[self.options.valueKey]
          })

          serie.valueKey = self.options.valueKey
          serie.tooltipFields = self.options.tooltipFields
        })

        if (self.series.length > 0 && self.series[0]?.name !== i18n.t("w_timeline_chart_no_data")) {
          self.series.forEach(function(serie) {
            serie.data = []

            let same_serie = _filter(series, function(s) { return s.name == serie.name })[0]
            if(same_serie) {
              serie.data = same_serie.data
              serie.rawData = same_serie.rawData
            }
          })
        }
        else {
          self.series = series
        }

        if (data?.labels) {
          self.categories = data.labels.map(function(label) { return _values(label)[0] })
          self.raw_categories = data.labels.map(function(label) { return _keys(label)[0] })
        } else {
          data.labels = []
        }

        // Define period selection
        if(self.period_to_selector != 1000 && self.period_to_selector > self.options.periodNumber) self.period_to_selector = self.options.periodNumber
        if(self.period_from_selector != 1000 && self.period_from_selector < 1) self.period_from_selector = 1
        if(self.options.unit == "quarters") self.period_to_selector = self.period_from_selector

        self.isLoading = false
        self.isFirstLoading = false

        self.$emit('loadingStatusChanged', false);
      })
    }, 300, { leading: true })
  },
  watch: {
    defaultOptions: {
      handler: 'dataChanged'
    },
  }
}
</script>
