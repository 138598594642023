<template>
  <div style="width: 100%" class="w-timeline-chart">
    <h2
      v-if="!!title"
      class="text-f10"
    >
      {{ title }}
    </h2>

    <div class="chart-container">
      <div class="chart">
        <Chart :options="chartOptions" ref="chart" :width="100" :height="100" />
      </div>
    </div>

    <div class="chart-timeline-loading" v-if="isLoading">
      <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
    </div>
  </div>
</template>

<style lang="stylus">
  .chart-container
    position: relative
    width: 100%
    height: 400px

    .chart
      position: absolute
      width: 100%

  .chart-timeline-navigation
    margin-top: -46px
    position: relative
    padding: 8px

  .chart-timeline-navigation button.previous
    float: left

  .chart-timeline-navigation button.next
    float: right

  .chart-timeline-loading
    margin-right: 1px

</style>

<script>
import { Chart } from "highcharts-vue"
import dayjs from "dayjs"
import { i18n } from '@i18n/setup'
import _debounce from "lodash/debounce"
import _keys from "lodash/keys"
import _values from "lodash/values"
import _map from "lodash/map"
import _uniq from "lodash/uniq"

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const DEFAULT_COLORS = ["#FFB800", "#DB3572", "#88C788", "#BA0022", "#2E1C4D", "#C48754", "#4EBDB7", "#4F64B6", "#C1CC75", "#DBA951", "#B96785", "#874B8B", "#4BAEE4", "#6F63BA", "#2699A6", "#9D8B52", "#7DBD96", "#278DDA", "#B473BB", "#98BBB8", "#98A255", "#E8DF81", "#BD7953", "#E2BCA4"]

export default {
  name: "WBasedChart",
  data() {
    return {
      isLoading: false,
      series: [],
      categories: [],
      currentTime: Date.now(),
      history: {}
    }
  },

  components: { Chart },

  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    options: {
      type: Object,
      required: true
    },
    colorLines: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    },
    colorBackground: {
      type: String,
      required: false,
      default: "transparent"
    },
    colorBorders: {
      type: String,
      required: false,
      default: "#deefee"
    },
    colorChartBorders: {
      type: String,
      required: false,
      default: "transparent"
    },
    colorPeriod: {
      type: String,
      required: false,
      default: "#E5EAF2"
    },
    colorMarkerMainLine: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    },
    connectNulls: {
      type: Boolean,
      required: false,
      default: false
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    chartType: {
      type: String,
      required: false,
      default: "column"
    }
  },

  mounted: function() {

    this.options.chartOptions = this.options.chartOptions || {}
    this.options.maxDate = this.options.maxDate || this.options.to || dayjs()
    this.options.minDate = this.options.minDate || dayjs().subtract(100, 'years')
    if(typeof(this.options.maxDate) === "string") this.options.maxDate = dayjs(this.options.maxDate, this.dateFormat)
    if(typeof(this.options.minDate) === "string") this.options.minDate = dayjs(this.options.minDate, this.dateFormat)

    this.options.tickUnit = this.options.tickUnit || "day"
    this.options.unit = this.options.unit || this.options.tickUnit + "s"
    this.options.tickNumber = this.options.tickNumber || 1

    this.options.tooltipFields = this.options.tooltipFields || []

    this.options.tooltip = this.options.tooltip || function() {
      var score = parseFloat(this.series.processedYData[this.point.index]).toFixed(2).replace(".", ",")
      let userOptions = this.series.userOptions
      let tooltipFields = userOptions.tooltipFields

      let html = '<div>'
      html = '<h1 style="background: #454F55; padding: 10px; color: #fff; font-size: 14px; text-align: center;">' + this.key + '</h1>'
      html += '<div style="background: #454F55; padding: 10px; color: #ACB6C1;text-align:center;">'
      html += '<div>' + this.series.name + "</div><span style='font-weight: 700;color: white; font-size: 24px;'>" + score + "</span>"
      tooltipFields.forEach(function(value, key) {
        html += '<div>' + rawData[this.point.index][key] + " " + value +"</div>"
      })
      html += "</div>"
      html += "</div>"

      return html
    }

    var period_number = 8

    switch (this.options.unit) {
      case 'weeks':
        period_number = 4;
        break;
      case 'months':
        period_number = 12;
        break;
      case 'years':
        period_number = 5;
        break;
    }

    this.options.periodNumber = this.options.periodNumber || period_number

    this.options.to = this.options.to || dayjs()
    if(typeof(this.options.to) === "string") this.options.to = dayjs(this.options.to, this.dateFormat)

    if(this.options.maxDate > this.options.to) this.options.maxDate = this.options.to

    this.options.from = this.options.from || this.options.to.subtract(this.options.periodNumber, this.options.unit)
    if(typeof(this.options.from) === "string") this.options.from = dayjs(this.options.to, this.dateFormat)

    if(this.options.unit == "days") {
      this.options.periodFrom = this.options.periodFrom || this.options.to.format("YYYY-MM-DD")
      this.options.periodTo = this.options.periodTo || this.options.to.format("YYYY-MM-DD")
    }

    if(this.options.unit == "weeks") {
      this.options.from = this.options.from.endOf('week').add(1, 'day').startOf('week')
      this.options.to = this.options.to.endOf('week')
      this.options.periodFrom = this.options.periodFrom || this.options.to.startOf("week").format("YYYY-MM-DD")
      this.options.periodTo = this.options.periodTo || this.options.to.endOf("week").format("YYYY-MM-DD")
    }

    if(this.options.unit == "quarters") {
      this.options.from = this.options.from.endOf('quarter').add(1, 'day').startOf('quarter')
      this.options.to = this.options.to.endOf('quarter')
      this.options.periodFrom = this.options.periodFrom || this.options.to.startOf("quarter").format("YYYY-MM-DD")
      this.options.periodTo = this.options.periodTo || this.options.to.endOf("quarter").format("YYYY-MM-DD")
    }

    if(this.options.unit == "months") {
      this.options.from = this.options.from.endOf('month').add(1, 'day').startOf('month')
      this.options.to = this.options.to.endOf('month')
      this.options.periodFrom = this.options.periodFrom || this.options.to.startOf("month").format("YYYY-MM-DD")
      this.options.periodTo = this.options.periodTo || this.options.to.endOf("month").format("YYYY-MM-DD")
    }

    this.refreshData()
  },

  computed: {
    chartOptions () {
      var self = this

      let options = {
        chart: {
          type: this.chartType,
          backgroundColor: this.colorBackground,
          borderColor: this.colorChartBorders,
          borderWidth: 1
        },
        credits: { enabled: false },
        title: { text: "" },
        tooltip: {
          shared: false,
          useHTML: true,
          shadow: false,
          borderRadius: 8,
          borderWidth: 0,
          padding: 0,
          formatter: this.options.tooltip
        },
        plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
          }
        },
        xAxis: {
          fill: this.colorBorders,
          categories: this.categories,
          gridLineColor: this.colorBorders,
          gridLineWidth: 1,
          plotBands: { color: this.colorPeriod, from: self.period_from_selector, to: self.period_to_selector }
        },
        yAxis: {
          gridLineColor: this.colorBorders,
          gridLineWidth: 1,
          title: { text: "" }
        }
      }

      let series = this.series.map ( (s, index) => {
        return {
          ...s,
          color: this.colorLines[index],
          dashStyle: "Solid",
          connectNulls: this.connectNulls,
          marker: {
            fillColor: this.colorMarkerMainLine[index],
            radius: 3
          }
        }
      })

      const chartOptions = { ...options, ...this.options.chartOptions }
      return { ...chartOptions, series }
    }
  },

  methods: {
    async getData() {
      var self = this
      var opts = this.options
      var key = opts.from.format("YYYY-MM-DD") + "-" + opts.to.format("YYYY-MM-DD")
      if(!this.history[key]) {

        const response = await opts.request.to_series(
          self.options.labels
        )

        this.history[key] = response.data
      }

      return this.history[key]
    },

    refreshData: _debounce(function() {
      var self = this
      self.period_from_selector = 10
      self.period_to_selector = 10
      this.isLoading = true

      this.getData().then(function(data) {
        self.categories = _keys(data)
        var unique_keys = _uniq(_map(data, function(d, id) { return _map(d, function(v, k) { return k })}).flat())
        var series = []
        unique_keys.forEach(function(key) {
          series.push(
            {
              name: key,
              data: _map(data, function(d, id) { return d[key] }),
              tooltipFields: self.options.tooltipFields
            }
          )
        })
        self.series = series

        self.isLoading = false
        window.dispatchEvent(new Event('resize'))
      })
    }, 300, { leading: true })
  }
}
</script>
